<template>
  <div>
    <vue-dropzone
      v-if="!(filesArrayV2.length && !multiple)"
      @vdropzone-file-added="addFile"
      @vdropzone-removed-file="removeFile"
      :options="dropzoneOptions"
      :useCustomSlot="true"
      :class="{'br-error': errorMessages.length}"
      ref="dropzone"
      id="dropzone"
      >
      <unicon
        name="upload"
        fill="#42627e"
        height="50px"
        width="50px"
      />
      <div class="m-1">
        {{ $t('dropFile') }}
      </div>
    </vue-dropzone>

    <div v-show="cropping && filesArrayV2.length">
      <v-icon v-if="!multiple" @click="filesArray = []; filesArrayV2 = []">mdi-close</v-icon>
      <!-- <CropperImg ref="cropppImg" :images="filesArrayV2"/> -->
      <Cropper ref="newCropper"/>
    </div>

  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'cropperjs/dist/cropper.css'
import { mapActions } from 'vuex'
// import { Cropper } from 'vue-advanced-cropper'
// import 'vue-advanced-cropper/dist/style.css'
// import CropperImg from '@/components/modules/Cropper'

export default {
  name: 'DropZone',
  components: {
    vueDropzone: vue2Dropzone,
    Cropper: () => import('../Cropper.vue')
    // CropperImg,
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    cropping: {
      type: Boolean,
      default: false
    },
    existPhoto: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: 'image/jpeg, image/png, image/jpg, application/pdf'
    },
    errorMessages: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      filesArray: [],
      imgSrc: null,
      cropImg: null,
      filesArrayV2: [],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        maxFilesize: 40,
        maxFiles: this.multiple ? 10 : 1,
        acceptedFiles: this.accept,
        addRemoveLinks: true,
        autoProcessQueue: false,
        dictRemoveFile: this.$i18n.t('removeFile'),
        dictFileTooBig: `${this.$i18n.t('tooHeavyFile')}. ${this.$i18n.t('maxFileSize')} - {{maxFilesize}}MB`,
        dictInvalidFileType: this.$i18n.t('invalidDataFormat')
      }
    }
  },
  watch: {
    existPhoto (value) {
      value && this.loadExistPhoto()
    }
  },
  mounted () {
    this.editOldFile()
  },
  methods: {
    ...mapActions(['getFile']),
    loadExistPhoto () {
      // eslint-disable-next-line no-return-await
      fetch(this.existPhoto).then(resp => resp.blob()).then(response => {
        this.objToBase64(response).then(result => {
          this.filesArrayV2.push({ origin: result, cropped: null })
        })
      })
    },
    /** Edit file */
    async editOldFile () {
      if (this.existPhoto) {
        let file = await this.getFile(this.existPhoto)
        this.addFile(file)
      }
    },
    /** Add new file to array */
    addFile (file) {
      setTimeout(() => {
        if (file.status !== 'error') {
          this.filesArray.push(file)

          if (this.cropping) {
            this.objToBase64(file).then(result => {
              this.filesArrayV2.push({ origin: result, cropped: null })
            })
          }
        }

        this.$refs.newCropper.setImage(file)
      }, 1)
    },

    /** Remove file from array */
    removeFile (file) {
      this.filesArray.splice(this.filesArray.indexOf(file), 1)
    },

    removeAllFile () {
      this.$refs.dropzone.removeAllFiles()
      this.filesArray = []
    },

    objToBase64 (file) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      async function Main () {
        // eslint-disable-next-line no-return-await
        return await toBase64(file)
      }

      return Main()
    },

    base64ToBlob (base64) {
      let mimeType = this.mimeTypeFile(base64.split(',')[1][0])
      const byteNumbers = new Array(base64.length)
      for (let i = 0; i < base64.length; i++) {
        byteNumbers[i] = base64.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      return new Blob([byteArray], { type: mimeType.mimetype })
      // return {
      //   file: new File([blob], fileName, { type: mimeType.mimetype }),
      //   ext: mimeType.ext
      // }
    },

    mimeTypeFile (symbol) {
      // '/' - jpeg, 'i' - png, 'R' - gif, 'J' - PDF
      switch (symbol) {
        case '/':
          return { mimetype: 'image/jpeg', ext: 'jpg' }
        case 'i':
          return { mimetype: 'image/png', ext: 'png' }
        case 'R':
          return { mimetype: 'image/gif', ext: 'gif' }
        case 'J':
          return { mimetype: 'application/pdf', ext: 'pdf' }
        default:
          return { mimetype: 'image/jpeg', ext: 'jpg' }
      }
    }
  }
}

</script>

<style scoped>
  .cropper {
    width: 300px !important;
    height: 400px !important;
    background: #DDD;
  }
  .vue-advanced-cropper__stretcher {
    width: 300px !important;
    height: 400px !important;
  }
  .br-error {
    border: 2px solid #ef476f
  }
</style>
